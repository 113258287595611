<template>
  <v-btn
    small
    @click="onSyncMap"
  >
    <v-icon left>
      {{ iconSync }}
    </v-icon>
    {{ $t('sync_map') }}
  </v-btn>
</template>

<script>
import { mdiSync } from '@mdi/js'
import vuexMixin from '@/mixins/vuexMixin'

export default {
  name: 'CMSyncMap',
  mixins: [vuexMixin],
  data () {
    return {
      iconSync: mdiSync
    }
  },
  methods: {
    onSyncMap () {
      this.setVuexElement({ path: 'syncMap', value: true })
    }
  }
}
</script>
